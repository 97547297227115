import React from "react";
import FlipMove from "react-flip-move";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Banner from "../../components/banner";

import SideBar from "../../components/blog/sidebar";

class Blog extends React.Component {
  state = {
    data: null,
    filtre: "toutes"
  };

  componentDidMount() {
    const data = this.props.data.allMarkdownRemark.edges;
    if (this.props.location.state.filtre) {
      this.setState({ data: data, filtre: this.props.location.state.filtre });
    }
    this.setState({ data: data });
  }

  handleCategorie = e => {
    e.preventDefault();
    const text = e.target.text.toLowerCase();
    this.setState({ filtre: text });
  };

  render() {
    let data = this.props.data.allMarkdownRemark.edges;
    if (this.state.filtre !== "toutes") {
      data = this.state.data.filter(
        elem => elem.node.frontmatter.categorie.indexOf(this.state.filtre) > -1
      );
    }

    return (
      <Layout>
        <SEO
          title="Notre Blog"
          description="Découvrez tous nos conseils et astuces pour développer votre activité en ligne : optimisation de votre site, référencement, analyse des performances, etc."
        />
        <Banner
          title="Blog"
          baseline="Des conseils et astuces pour votre site internet !"
          imageBanner={this.props.data.imageBanner.childImageSharp.fluid}
        />
        <section
          id="blog-grid-2columns"
          className="blog blog-grid blog-grid-2columns"
        >
          <div className="container">
            <div className="row">
              <SideBar handleCategorie={this.handleCategorie} />

              <div className="col-sm-12 col-md-8 col-lg-8 mb-30-xs mb-30-sm">
                <FlipMove
                  duration={750}
                  easing="ease-out"
                  appearAnimation={true}
                  className="row"
                >
                  {data.map((elem, key) => {
                    return (
                      <div key={key} className="col-sm-12 col-md-6 col-lg-6">
                        <div className="entry text-center">
                          <div className="entry-featured">
                            <a href={elem.node.frontmatter.path}>
                              <Img
                                fluid={
                                  elem.node.frontmatter.image.childImageSharp
                                    .fluid
                                }
                                alt={elem.node.frontmatter.title}
                              />
                            </a>
                          </div>
                          {/* <div className="entry-date">
                            <span>{elem.node.frontmatter.date}</span>
                          </div> */}
                          <div className="entry-title text-center">
                            <h3>
                              <a href={elem.node.frontmatter.path}>
                                {elem.node.frontmatter.title}
                              </a>
                            </h3>
                          </div>
                          <div className="entry-meta text-center">
                            <span className="tag-article">
                              {elem.node.frontmatter.categorie}
                            </span>
                            <span className="tag-article">
                              {elem.node.frontmatter.niveau}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </FlipMove>

                {/*
                <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <nav className="text-center" aria-label="Page navigation">
                    <ul className="pagination">
                      <li>
                        <a href="#" aria-label="Previous">
                          <span aria-hidden="true">Précédent</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">1</a>
                      </li>
                      <li className="active">
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#" aria-label="Next">
                          <span aria-hidden="true">Prochain</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>*/}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            path
            title
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
            linkedin
            categorie
            niveau
          }
        }
      }
    }
    imageBanner: file(relativePath: { eq: "background/bannerBlog2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Blog;

/*
<div>
{
  data.allMarkdownRemark.edges.map(elem => {
    return (
      <div className="col-sm-12 col-md-12 col-lg-8 mb-30-xs mb-30-sm">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <div className="entry text-center">
            <div className="entry-featured">
              <a href={elem.node.frontmatter.path}>
                <img src={Image2} alt="entry title" />
              </a>
            </div>
            <div className="entry-date">
              <a href="#">{elem.node.frontmatter.date}</a>
            </div>
            <div className="entry-title text-center">
              <h3>
                <a href="blog-single-post-fullwidth.html">
                {elem.node.frontmatter.title}
                </a>
              </h3>
            </div>
            <div className="entry-meta text-center">
              <span>By : </span>
              <a href="#">{elem.node.frontmatter.author}</a>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  })
}
</div>
*/
