import React from "react";
import Image1 from "../../assets/images/background/sidebarBlog.jpg";
import { Link } from "gatsby";

const SideBar = ({ handleCategorie }) => {
  return (
    <div className="col-sm-12 col-md-4 col-lg-4 sidebar ">
      <div className="widget widget-categories">
        <div className="widget-title">
          <h5>catégories</h5>
        </div>
        <div className="widget-content">
          <ul className="list-unstyled">
            <li>
              <Link
                to="/blog"
                onClick={handleCategorie}
                state={{ filtre: "toutes" }}
              >
                toutes
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                onClick={handleCategorie}
                state={{ filtre: "tech" }}
              >
                tech
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                onClick={handleCategorie}
                state={{ filtre: "glossaire" }}
              >
                glossaire
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                onClick={handleCategorie}
                state={{ filtre: "cas pratique" }}
              >
                cas pratique
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="widget widget-about">
        <div className="widget-title">
          <h5>À propos</h5>
        </div>
        <div className="widget-content">
          <img src={Image1} alt="About Widget" />
          <p>
            Nous créons des sites web, rapides, sécurisés et optimisés pour
            booster votre activité
          </p>
          <Link to="/offres" className="widget-more ">
            <i className="fal fa-long-arrow-alt-right fa-2x" />
            Voir nos offres
          </Link>
          {/* <div className="social-accounts">
            <a href="#">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#">
              <i className="fab fa-twitter" />
            </a>
            <a href="#">
              <i className="fab fa-linkedin-in" />
            </a>
          </div> */}
        </div>
      </div>
      {/*
      <div className="widget widget-recent">
        <div className="widget-title">
          <h5>Articles similaires</h5>
        </div>
        <div className="widget-content">
          <div className="entry">
            <img src={Image2} alt="title" />
            <div className="entry-desc">
              <div className="meta-date">
                <a href="#">Nov 29, 2018</a>
              </div>
              <div className="entry-title">
                <a href="#">Home Tour, Modern Framehouse with global.</a>
              </div>
              <div className="entry-meta">
                <span>
                  by: <a href="#"> Begha</a>
                </span>
                <span className="slash"> / </span>
                <span>comments: 27</span>
              </div>
            </div>
          </div>

          <div className="entry">
            <img src={Image2} alt="title" />
            <div className="entry-desc">
              <div className="meta-date">
                <a href="#">Nov 27, 2018</a>
              </div>
              <div className="entry-title">
                <a href="#">How to organize your office workplace?</a>
              </div>
              <div className="entry-meta">
                <span>
                  by: <a href="#"> ayman</a>
                </span>
                <span className="slash"> / </span>
                <span>comments: 17</span>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      */}
    </div>
  );
};

export default SideBar;
